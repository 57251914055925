























import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import { Message, MessageBox, Loading } from "element-ui";

@Component({
  name: "Login",
})
export default class extends Vue {
  // 倒计时
  codeTime = 0;
  private login = {
    username: sessionStorage.getItem("phone") || '',
    verifycode: "",
  };

  ///////////////////////////////////////////
  codeloading = false;
  getCodeTime() {
    if (this.codeloading) {
      return "获取中";
    }
    return this.codeTime
      ? (this.codeTime < 10 ? "0" : "") + this.codeTime + "秒"
      : "获取验证码";
  }
  GetCode(): void {
    // 是否正在获取验证码，由于网络过慢，防重复点击
    if (this.codeloading) {
      return;
    }
    // 是否倒计时完毕
    if (this.codeTime) {
      return;
    }
    // 验证手机号
    if (!this.checkPhone(this.login.username)) {
      return;
    }
    this.codeloading = true;
    // 调用短信接口 ajax
    api
      .getVerCode({ mobile: this.login.username })
      .then(() => {
        this.codeloading = false;
        // 60秒倒计时
        this.codeTime = 60;
        // 启用倒计时
        setTimeout(() => {
          this.TimeReduce();
        }, 1000);
      })
      .catch(() => {
        this.codeloading = false;
      });
  }

  checkPhone = (phone: string) => {
    if (!phone) {
      Message.warning("请输入手机号");
      return false;
    }
    if (phone.length !== 11) {
      Message.warning("手机号长度不正确");
      return false;
    }
    if (!/^1[3456789]\d{9}$/.test(phone)) {
      Message.warning("手机号格式不正确");
      return false;
    }
    return true;
  };

  /*
   * 获取验证码倒计时
   * 2020年4月21日
   * 周祥
   */
  TimeReduce(): void {
    // 如果倒计时没有完毕 则继续执行
    if (this.codeTime) {
      // 倒计时递减
      this.codeTime--;
      // 延迟一秒执行
      setTimeout(() => {
        this.TimeReduce();
      }, 1000);
    }
  }

  loading = false;

  goLogin() {
    if (this.loading) {
      return;
    }
    if (!this.checkPhone(this.login.username)) {
      return;
    }
    if (!this.login.verifycode) {
      Message.warning("请输入验证码");
      return;
    }
    this.loading = true;
    api
      .getToken({
        mobile: this.login.username,
        verifycode: this.login.verifycode,
      })
      .then((data) => {
        this.loading = false;
        sessionStorage.setItem("phone", this.login.username);
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("system", "17");
        location.href = "/#/";
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
